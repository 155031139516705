var header;

window.onload = init();

function init() {
  header = document.getElementById("header");

  var windowWidth = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  );

  if (
    document.getElementById("impressum") ||
    document.getElementById("datenschutz") ||
    windowWidth < 767
  ) {
    document.getElementById("header").classList.add("sticky");
  } else {
    window.onscroll = scroll;
  }

  if (windowWidth < 767) {
    initMobileNav();
  }

  pv.init({
    container: {
      height: "50vw"
    },
    block: {
      speed: -7
    }
  });

  setTimeout(function() {
    gumshoe.init({
      selector: ".header-container__navigation a",
      selectorHeader: "header",
      offset: 60
    });
  }, 1000);

  var toggleElements = document.getElementsByClassName("nst-toggle");

  for (var i = 0; i < toggleElements.length; i++) {
    toggleElements[i].onclick = function(event) {
      setTimeout(function() {
        gumshoe.setDistances();
      }, 500);
    };
  }

  var smoothScroll = new SmoothScroll('a[href*="#"]', {
    offset: 100,
    updateURL: false
  });

  if (document.getElementById("main")) {
    introAnimation();
  }
}

function scroll() {
  var distance = Math.abs(getDistance() - window.pageYOffset);

  if (distance >= 10) {
    header.classList.add("sticky");
  } else {
    header.classList.remove("sticky");
  }
}

function getDistance() {
  var topDist = header.offsetTop;
  return topDist;
}

function introAnimation() {
  var elements = document.getElementsByClassName("text-animation");
  var index = 0;

  setInterval(function() {
    if (index === 0) {
      elements[index].classList.remove("active");
      setTimeout(function() {
        elements[index + 1].classList.add("active");
        index = 1;
      }, 250);
    } else {
      elements[index].classList.remove("active");
      setTimeout(function() {
        elements[0].classList.add("active");
        index = 0;
      }, 250);
    }
  }, 4000);
}

function initMobileNav() {
  var toggleOpen = document.getElementById("menu-open");
  var toggleClose = document.getElementById("menu-close");
  var header = document.getElementById("header");
  var nav = document.getElementById("nav");

  toggleOpen.addEventListener("click", function() {
    header.classList.add("nav-open");
    nav.classList.add("open");
  });

  toggleClose.addEventListener("click", function() {
    header.classList.remove("nav-open");
    nav.classList.remove("open");
  });

  var links = nav.getElementsByTagName("a");

  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener("click", function() {
      header.classList.remove("nav-open");
      nav.classList.remove("open");
    });
  }
}
